.character {
  color: var(--chakra-colors-textSecondary);
  border-radius: 5px;
  border: 1px solid #cfae89;
}

.character--inactive {
  border: none;
  background: #f5f5f5;
}
