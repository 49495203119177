.slick-disabled {
  display: none !important;
}

a.MsoHyperlink,
span.MsoHyperlink {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: #3dcdb5;
  font-weight: 600;
}
a.MsoHyperlink:hover,
span.MsoHyperlink:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
